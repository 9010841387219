import { formatCurrencyNumber } from '#helpers/numbers'
import { useFormik } from 'formik'
import { Center, HStack, Heading, Input, Stack, Text, VStack, useMediaQuery } from 'native-base'

const bills = [
  {
    amount: 10,
    value: 10,
  },
  {
    amount: 20,
    value: 20,
  },
  {
    amount: 50,
    value: 50,
  },
  {
    amount: 100,
    value: 100,
  },
  {
    amount: 200,
    value: 200,
  },
  {
    amount: 500,
    value: 500,
  },
  {
    amount: 1000,
    value: 1000,
  },
  {
    amount: 2000,
    value: 2000,
  },
  {
    amount: 10000,
    value: 10000,
  },
  {
    amount: 20000,
    value: 20000,
  },
]

const initValues = bills.reduce(
  (prev, curr) => ({
    ...prev,
    [`bills_${curr.amount}`]: 0,
  }),
  {},
)

const getTotal = (values: any) => {
  const array = Object.entries(values).map(([key, value]) => {
    return {
      value: Number(value),
      amount: Number(key.replace('bills_', '')),
    }
  })

  return array.reduce((prev, curr) => {
    return prev + curr.amount * curr.value
  }, 0)
}

const Home = () => {
  const [isSmallScreen] = useMediaQuery({
    minWidth: 0,
    maxWidth: 450,
  })

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: () => {},
  })

  return (
    <VStack flex={1}>
      <Center h="full">
        <Heading textAlign="center" textTransform="uppercase" mb={5}>
          Calculadora de Billetes
        </Heading>
        <Stack width={isSmallScreen ? 'full' : '1/2'} p={4}>
          <HStack alignItems="flex-start">
            <Text width="1/4" textAlign="center" fontWeight="bold" textTransform="uppercase">
              Billete
            </Text>
            <Text
              width="1/2"
              textAlign={isSmallScreen ? 'center' : 'right'}
              fontWeight="bold"
              textTransform="uppercase">
              Cantidad
            </Text>
            <Text
              width={isSmallScreen ? '1/3' : '1/4'}
              textAlign="right"
              fontWeight="bold"
              textTransform="uppercase">
              Monto
            </Text>
          </HStack>

          {bills.map((bill, index) => (
            <HStack alignItems="center" key={index} mt={2}>
              <Text width="1/4" textAlign="center">
                {formatCurrencyNumber(bill.amount)}
              </Text>
              <Text width="1/2" textAlign={isSmallScreen ? 'center' : 'right'}>
                <Input
                  textAlign="right"
                  onKeyPress={(e) => {
                    // @ts-expect-error
                    const keyCode = e.nativeEvent.keyCode

                    if (
                      keyCode === 8 || // Backspace
                      keyCode === 9 || // Tab
                      keyCode === 46 || // Delete
                      (keyCode >= 37 && keyCode <= 40) // Arrow keys
                    ) {
                      return
                    }

                    if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122)) {
                      e.preventDefault()
                    }
                  }}
                  keyboardType="number-pad"
                  onChangeText={formik.handleChange(`bills_${bill.amount}`)}
                  maxLength={6}
                />
              </Text>
              <Text width={isSmallScreen ? '1/3' : '1/4'} textAlign="right">
                {/* @ts-expect-error object typing */}
                {formatCurrencyNumber(Number(formik.values[`bills_${bill.amount}`]) * bill.value)}
              </Text>
            </HStack>
          ))}

          <HStack alignItems="flex-end" mt={3}>
            <Text width="1/2" textAlign="center" fontWeight="bold" textTransform="uppercase" />
            <Text
              width={isSmallScreen ? '1/3' : '1/4'}
              textAlign={isSmallScreen ? 'left' : 'right'}
              fontWeight="bold"
              textTransform="uppercase">
              Total
            </Text>
            <Text width="1/4" textAlign="right" fontWeight="bold" textTransform="uppercase">
              {formatCurrencyNumber(getTotal(formik.values))}
            </Text>
          </HStack>
        </Stack>
      </Center>
    </VStack>
  )
}

export default Home
